import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
// Notification
import Vue from "vue";

export default function usePriceoffersList() {
  // Use toast

  // const toast = useToast();


  // if (Designations) emit('addDesignation')
  const refFamiliesListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "pr_code", label: "طلب الشراء ", sortable: false },
    { key: "price_offers", label: "تفاصيل العرض  ", sortable: false },
   
    
  ];
  const perPage = ref(5);
  const totalFamilies = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [5,10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refFamiliesListTable.value
      ? refFamiliesListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalFamilies.value,
    };
  });
  
  const refetchData = () => {
    refFamiliesListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });


  const fetchPrice = (ctx, callback) => {
    
    return new Promise((resolve, reject) => {
      let url=`/api/v1/price-offer-list`
      axios
      .get(url, {
        params: {
            search: searchQuery.value, // Assuming searchQuery.value holds the search query
            page_size: perPage.value, // Assuming perPage.value holds the number of items per page
            page: currentPage.value // Assuming currentPage.value holds the current page number
        }
    })
        .then(response => {
          const { data, total } = response.data;
     //  console.log(response.data.data)
          callback(data);
          totalFamilies.value = response.data.meta.total;
        })
        .catch(error => {
          Vue.swal({
            title: "حدثت مشكلة في استرجاع البيانات",
    icon: "error",
         
      confirmButtonText: "موافق",
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
    });
    
         
        })
    })
   
      
  
       
      
  };


  return {
    fetchPrice,
    
    tableColumns,
    perPage,
    currentPage,
    totalFamilies,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFamiliesListTable,

    refetchData,
  };
}
