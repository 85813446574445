<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title=" تعديل عرض السعر ">
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-row>
              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="employe"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> طلب الشراء</label>
                    <v-select
                      v-model="transaction_id"
                      :options="optionPurchesOrder"
                      :reduce="(val) => val.value"
                      @input="getDetails(transaction_id)"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="employe"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> المورد</label>
                    <v-select
                      v-model="vendor_id"
                      label="name"
                      :options="optionVendor"
                      :reduce="(val) => val.id"
                      @input="getVendorId(vendor_id)"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="file"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>   الملف</label>
                    <b-form-file
                      v-model="file"
                      accept=".pdf"
                    />
                    <a
          target="_blank"
            :href="file">
            <feather-icon icon="LinkIcon" />
            عرض الملف
          </a>
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-group
                    label-for="notes"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> ملاحظة</label>
                    <b-form-input
                      id="input-default"
                      v-model="notes"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
               <b-col
                md="3"
                xl="3"
              >
               
                  <b-form-group
                    label-for="notes"
                
                  >

                    <label> الميزانية المقدرة</label>
                   {{budget}}
                  </b-form-group>
                 
              
              </b-col>


              <b-col>
                <b-form-group>
                  <b-form-checkbox
                    v-model="isWinner"
                  >
                    العرض الفائز
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

            </b-row>
            <b-row>
              <b-col
                v-for="(item,index) in details"
                cols="12"
              >
                <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                <div class="d-none d-lg-flex">

                  <b-row class="flex-grow-1 px-1">
                    <!-- Single Item Form Headers -->
                    <b-col
                      cols="2"
                      lg="2"
                    >
                    
                      <label>
                        التفاصيل
                      </label>
                    </b-col>

                  </b-row>
                  <div class="form-item-action-col" />
                </div>

                <!-- Form Input Fields OR content inside bordered area  -->
                <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                <div class="d-flex border rounded">
                  <b-row class="flex-grow-1 p-2">
                    <!-- Single Item Form Headers -->
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false : null"
                        >
                          <label>المادة</label>
                          <v-select
                            v-model="item.item_id.id"
                            :dir="
                              $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                            "
                            :options="ItemData"
                            label="name"

                            :clearable="false"
                            :reduce="(val) => val.id"
                            class="mb-2 item-selector-title"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="2"
                      lg="2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false : null"
                        >
                          <label>الكمية</label>
                          <b-form-input
                            v-model="item.quantity"
                            type="number"
                            class="mb-2"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                      
                        <b-form-group
                          label-for="notes"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label>الواحدة</label>
                          <v-select
                            v-model="item.unit_id.id"
                            :dir="
                              $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                            "
                            :options="ubitsOption"
                            label="en_name"
                            :clearable="false"
                            :reduce="(val) => val.id"
                            class="mb-2 item-selector-title"
                          />

                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false : null"
                        >
                          <label>العملة</label>
                          <v-select
                            v-model="item.currency_id.id"
                            :dir="
                              $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                            "
                            :options="optionCurrency"

                            :clearable="false"
                            :reduce="(val) => val.value"
                            class="mb-2 item-selector-title"
                            
                            
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          label-for="notes"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label>السعر</label>
                          <b-form-input
                            v-model="item.price"
                            type="text"
                            placeholder="السعر "
                            class="mb-2"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col
                      md="3"
                      xl="3"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          label-for="employe"
                          :state="errors.length > 0 ? false : null"
                        >

                          <label> المورد</label>
                          <v-select
                            v-model="item.vendor_id"
                            label="name"
                            :options="optionVendor"
                            :reduce="(val) => val.id"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                      xl="3"
                      class="invoice-actions"
                    >
                      <!-- Payment Method -->
                      <div class="">
                        <b-form-group
                          label=" ملاحظة"
                          label-for="payment-method"
                        />
                        <b-form-input
                          v-model="item.notes"
                          type="text"
                        />
                      <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                      </div>
                    </b-col>
                    <b-col
                      cols="1"
                      lg="1"
                    >
                      <validation-provider
                        #default="{ errors }"
                      >
                        <b-form-group
                          label-for="notes"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class=""
                          >الاجمالي</label>
                          {{ item.total=item.quantity * item.price }}
                          <!-- <b-form-input
                                  v-model="item.total"
                                  type="text"
                                  placeholder="الاجمالي "
                                  class="mb-2"
                                /> -->
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="2"
                      lg="2"
                    >

                      <b-form-checkbox
                        :id="`checkbox-${index}`"
                        v-model="item.is_approved"
                        name="checkbox-1"
                        value="1"
                        unchecked-value="0"
                      >
                        مقبولة
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <div
                    class="d-flex flex-column justify-content-between border-left py-50 px-25"
                  >
                    <feather-icon
                      v-if="index != 0"
                      size="16"
                      icon="XIcon"
                      class="cursor-pointer"
                      @click="removeItem(index)"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class=" mt-2">
              <b-col
                cols="6"
                lg="6"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemForm"
                >
                  <span>
                    اضافة مادة اخرى
                  </span>
                </b-button>
              </b-col>
              <b-col
                cols="4"
                lg="4"
              >

                <label
                  class=""
                >الاجمالي الكلي</label>
                {{ totalAmount }}

              </b-col>

            </b-row>

            <b-col
              md="1"
              xl="3"
            >
              <b-button
                variant="purple"
                @click="updatePrice"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle"> تعديل عرض السعر </span>
              </b-button>
            </b-col>
          </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>
    
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Ripple from 'vue-ripple-directive'
import { required, max, is_earlier } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  VBToggle,

  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
  BModal,
  BTable,
  BPagination,
  BCard,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import usePriceoffersList from "./list";


import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'




export default {

  components: {
     BModal,
     BTable,
    flatPickr,
    ToastificationContent,
    required,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BPagination,
    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,
    BCard,
    BCol,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  setup(){
    const {
      tableColumns,
      perPage,
      currentPage,
      totalFamilies,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,

      fetchPrice,
      

      // Extra Filters
    } = usePriceoffersList()
    
    return {
      search: null,
      fetchPrice,

      filterOn: [],
      searchTerm: "",
      tableColumns,
      perPage,
      currentPage,
      totalFamilies,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,
    }

  },
  data() {
    return {
      file: '',
      body: '',
      vendor_id: '',
      modalValue:null,
      optionVendor: [],
      isWinner: false,
      itemFormBlankItem: {
        item_id: '',
        quantity: 1,
        total: 0,
        unit_id: '',
        price: 0,
        currency_id: 1,
        vendor_id: '',
        notes: '',
        is_approved: 1,
      },
      details: [
        {
          item_id: '',
          quantity: 1,
          total: 0,
          unit_id: '',
          price: 0,
          currency_id: 1,
          vendor_id: '',
          notes: '',
          is_approved: 1,
        },
      ],
      
      ubitsOption: [],
      ItemData: [],
      optionPurchesOrder: [],
      transaction_id: '',
      is_approved: 1,
      fieldsPrice: [
        { key: 'vendor', label: 'المانح ' },
        { key: 'file', label: 'الملف' },
        { key: 'prepared_by', label: 'الموظف' },
        { key: 'actions', label: 'الخيارات' },
      ],
      columns: [
        {
          label: 'طلب الشراء ',
          key: 'pr_code',
          
        },
        {
          label: 'تفاصيل العرض ',
          key: 'price_offers',
        },
       

      ],
      rows: [],
      date: '',
      notes: '',
      employe: '',
      id: '',
      searchTerm: '',
      optionerewardType: [
      ],
      item: '',
      budget: '',
      optionitem: [],
      optionCurrency: [],
      allTransaction: [],

  
      
    }
  },
  computed: {
    totalAmount() {
      let sum = 0
      for (let i = 0; i < this.details.length; i++) {
        sum += this.details[i].quantity * this.details[i].price
      }
      return sum
    },

   
  },

  created() {
    this.getPacts()
    this.getUnit()
    this.getVendor()
    this.getoptionCurrency()
    this.getPurchesOrder()
    this.getItem()
  },

  methods: {
    getVendorId(id) {
    
      this.details = this.details.map(el => {
      el.vendor_id = id;
      return el;
    });
     
    },
    getDetails(id) {
      this.details = this.allTransaction.find(el => el.id == id).details
      this.budget= this.allTransaction.find(el => el.id == id).budget
    },
    getoptionCurrency() {
      this.optionCurrency = []
      this.$http.get('/api/v1/currency').then(res => {
        // console.log(res);

        res.data.forEach(el => {
          this.optionCurrency.push({ value: el.id, label: `${el.name} ` })
        })
      // this.details[0].currency_id = res.data[0].id

      //    console.log(this.details);
      })
    },
    getPurchesOrder() {
      this.optionPurchesOrder = []
      this.$http.get('/api/v1/get-transactions-need-procurment-order').then(res => {
        // console.log(res);
        this.allTransaction = res.data.data
        res.data.data.forEach(el => {
          this.optionPurchesOrder.push({ value: el.id, label: `${el.code}  ` })
        })
        // console.log(this.optionemploye);
      })
    },
    getVendor() {
      this.optionVendor = []
      this.$http.get('/api/v1/vendor').then(res => {
        // console.log('itemres',res);
        this.optionVendor = res.data.data

        // console.log(this.optionVendor);
      })
    },
    getItem() {
      this.ItemData = []
      this.$http.get('/api/v1/item').then(res => {
        // console.log('itemres',res);
        this.ItemData = res.data.data.map(el => ({
          name: `${el.name} ${el.code}`,
          id: el.id,
        }))

        // console.log(this.ItemData);
      })
    },
    getUnit() {
      this.ubitsOption = []
      this.$http.get('/api/v1/unit').then(res => {
        // console.log('unit',res);
        this.ubitsOption = res.data

        // console.log(this.ubitsOption);
      })
    },

    deletePacts(id) {
      this.$swal({
        icon: 'warning',
        title: 'هل انت متأكد من الحذف',
        confirmButtonText: 'موافق',
        cancelButtonText: 'الغاء',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'لا',
        },
      }).then(result => {
        if (result.value) {
          const url = `/api/v1/price-offer/${id}`
          this.$http.delete(url).then(res => {
            // console.log(res);
            // this.getPacts()
            this.refetchData();
            this.$swal({
              icon: 'warning',
              title: 'تم الحذف  بنجاح',
              confirmButtonText: 'موافق',
              cancelButtonText: 'الغاء',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'لا',
              },
            })
          })
        }
      })
    },
     showModal(id) {
     //console.log(id)
     this.modalValue = id;
    // console.log(this.modalValue)
      // this.$refs[`my-modal-${id}`].show()
      const modalRef = this.$refs[`my-modal-${id}`];
      if(modalRef) {
        modalRef.show();
      }
    },
    addPacts() {
      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then(success => {
            if (success) {
              // console.log("this.details",this.details)
              const formData = new FormData()
              formData.append('transaction_id', this.transaction_id)
              formData.append('vendor_id', this.vendor_id)

              formData.append('notes', this.notes)
              // formData.append("is_approved",this.is_approved);
              formData.append('file', this.file)
              if (this.isWinner == true) { formData.append('is_winner_offer', 1) } else {
                formData.append('is_winner_offer', 0)
              }
              formData.append('details', JSON.stringify(this.details))

              // console.log("formData",formData)
              this.$http.post('/api/v1/price-offer', formData).then(res => {
                // console.log(res);
                this.transaction_id = ''
                this.vendor_id = ''
                this.notes = ''
                this.file = ''
                this.details = [{
                  item_id: '',
                  quantity: 1,
                  total: 0,
                  unit_id: '',
                  price: 0,
                  currency_id: 1,
                  vendor_id: '',
                  notes: '',
                  is_approved: 1,
                }]

                this.refetchData();
                this.$swal({
                  title: '',
                  text: 'تمت أضافة  بنجاح',
                  icon: 'success',
                  confirmButtonText: 'موافق',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
            }
          })
      })
    },

    addNewItemInItemForm() {
      this.details.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem)),
      )
         this.details = this.details.map(el => {
      el.vendor_id = this.vendor_id;
      return el;
    });
     
    },
    removeItem(index) {
      this.details.splice(index, 1)
    },
    getPacts() {
      
      this.$http.get(`/api/v1/price-offer/${this.$route.params.id}`).then(res => {
      //  console.log('prices', res.data.data)
        // this.rows = res.data.data
        this.vendor_id = parseInt(res.data.data.vendor_id.id)
        this.transaction_id = parseInt(res.data.data.transaction_id)
        this.notes = res.data.data.notes
        this.isWinner = res.data.data.is_winner_offer == 1? true : false
        this.details = res.data.data.details
        this.file = res.data.data.file

       
         
       
        
      })
    },
    updatePrice() {
      const formData = new FormData()
              formData.append('transaction_id', this.transaction_id)
              formData.append('vendor_id', this.vendor_id)

              formData.append('notes', this.notes)
              // formData.append("is_approved",this.is_approved);
              formData.append('file', this.file)
              if (this.isWinner == true) { formData.append('is_winner_offer', 1) } else {
                formData.append('is_winner_offer', 0)
              }
              formData.append('details', JSON.stringify(this.details))

      let url=`/api/v1/price-offer/${this.$route.params.id}`
       this.$http.put(url,formData).then((res) => {
      
        //console.log(res);
         this.$toast({
          component: ToastificationContent,
          props: {
            title: "تم التعديل بنجاح",
           icon: "CheckIcon",
              variant: "success",
            },
          },
          {
            position: "top-center",
          });
      });
    }
     
  },

 
}
</script>

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
button.btn.btn-primary.btn-sm {
    margin: 17px;
}
.but {
  top: 40px;
}
</style>
